import React, { useState, useEffect } from "react";
import { db, storage } from "../../firebase";
import { useNavigate, useParams } from "react-router-dom";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { addDoc, collection, getDoc, doc } from "firebase/firestore";
import { toast } from "react-toastify";

const initalState = {
  ebname: '',
  ebfield: '',
  ebdescription: '',
  ebcountry: '',
  ebcontact: ''
};

const AddEB = () => {
  const [form, setForm] = useState(initalState);
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(null);
  const { id } = useParams();

  const navigate = useNavigate();

  const { ebname, ebfield, ebdescription, ebcountry, ebcontact } = form;

  useEffect(() => {
    const uploadFile = () => {
      const storageRef = ref(storage, `EB_images/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setProgress(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is pasued");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
            toast.info("Image upload to fire base succesfully");
            setForm((prev) => ({ ...prev, ebimgUrl: downloadUrl }));
          });
        }
      );
    };

    file && uploadFile();
  }, [file]);


  useEffect(() => {
    id && getJournalDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getJournalDetails = async () => {
    const docRef = doc(db, 'eb');
    const snapshot = await getDoc(docRef);
    if (snapshot.exists()) {
      setForm({ ...snapshot.data() });
    }
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (ebname && ebfield && ebdescription && ebcountry && ebcontact) {
      await addDoc(collection(db, 'eb'), { ...form });
      toast.success("Created Successfully");
    } else {
      return toast.error("All fields are mandatory");
    }
    navigate("/");
  };

  return (
    <>
      <div className="m-8">
        <div className="">
          <div className="flex-col justify-center items-center">
            <h1 className="text-center text-xl font-bold underline underline-offset-8">Upload EB</h1>
            <div className="">
              <form className="blog-form space-y-8" onSubmit={handleSubmit}>
                <div className="w-full space-y-2">
                  <span> Name:</span>
                  <input
                    type="text"
                    className="border w-full px-4 py-2 rounded-md"
                    placeholder="Name"
                    name="ebname"
                    value={ebname}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-full space-y-2">
                  <span> Description:</span>
                  <textarea
                    className="px-3 py-2 w-full h-[16rem] border rounded-md"
                    placeholder="Description"
                    value={ebdescription}
                    name="ebdescription"
                    onChange={handleChange}
                  />
                </div>
                <div className="space-x-4 flex">
                  <span> Upload the Picture:</span>
                  <input
                    type="file"
                    className=""
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </div>
                <div className="w-full space-y-2">
                  <span>Field:</span>
                  <input
                    type="text"
                    className="border w-full px-4 py-2 rounded-md"
                    placeholder="Field"
                    name="ebfield"
                    value={ebfield}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-full space-y-2">
                  <span>Field:</span>
                  <input
                    type="text"
                    className="border w-full px-4 py-2 rounded-md"
                    placeholder="Country"
                    name="ebcountry"
                    value={ebcountry}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-full space-y-2">
                  <span>Contact Number: <span className="italic text-gray-400"></span></span>
                  <input
                    type="text"
                    className="border w-full px-4 py-2 rounded-md"
                    placeholder="Contact"
                    name="ebcontact"
                    value={ebcontact}
                    onChange={handleChange}
                  />
                </div>
                <div className="">
                  <button
                    className="duration-300 ease-in-out bg-[#7968ec] px-6 md:px-10 py-2.5 md:py-4  rounded-full text-white text-sm md:text-md font-semibold mt-2 hover:bg-[#7D69F9] hover:drop-shadow-md md:hover:drop-shadow-lg hover:shadow-[#BDB5EE] md:hover:shadow-[#BDB5EE]"
                    type="submit"
                    disabled={progress !== null && progress < 100}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEB;
