import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import {
  collection,
  getDocs,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "../../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

export const Add_article = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [a_title, setA_title] = useState("");
  const [author, setAuthor] = useState("");
  const [author_details, setAuthor_details] = useState("");
  const [sup, setSup] = useState("");
  const [email, setEmail] = useState("");
  const [tab3, setTab3] = useState("");
  const [file, setFile] = useState(null);
  const { title } = location.state || {};

  const uploadFileToStorage = async (file) => {
    const storageRef = ref(storage, `Articles/${file.name}`);
    const snapshot = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);
    return downloadURL;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (a_title && file && title && author && author_details && tab3 && email) {
      const formData = new FormData();
      formData.append("articleTitle", a_title);
      formData.append("file", file);
      formData.append("author", author);
      formData.append("author_details", author_details);
      formData.append("email", email);
      formData.append("sup", sup);
      formData.append("tab3", tab3);

      const fileLink = await uploadFileToStorage(file);

      const jnalQuery = query(
        collection(db, "jnal"),
        where("title", "==", title)
      );
      const querySnapshot = await getDocs(jnalQuery);

      if (!querySnapshot.empty) {
        const docSnapshot = querySnapshot.docs[0];

        if (docSnapshot.exists()) {
          const docRef = docSnapshot.ref;

          const docData = docSnapshot.data();
          let updatedArticles = [];

          if (docData && docData.article) {
            updatedArticles = [
              ...docData.article,
              {
                atitle: a_title,
                author: author,
                author_details: author_details,
                tab3: tab3,
                email: email,
                sup: sup,
                fileLink,
              },
            ];
          } else {
            updatedArticles = [
              {
                atitle: a_title,
                author: author,
                author_details: author_details,
                tab3: tab3,
                email: email,
                sup: sup,
                fileLink,
              },
            ];
          }

          await updateDoc(docRef, { article: updatedArticles });
          navigate("/manage_jnal");
        } else {
          alert("Document with title does not exist");
        }
      } else {
        alert("Document with title does not exist");
      }
    } else {
      alert("All fields are mandatory");
    }
  };

  return (
    <div class="p-8">
      <h1 class="text-3xl font-semibold">Add Article</h1>
      <form onSubmit={handleSubmit} class="mt-4">
        <div class="w-full space-y-2">
          <label for="author" class="block">
            Author:
          </label>
          <input
            id="author"
            type="text"
            class="border w-full px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Enter Article Title"
            name="author"
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
          />
        </div>
        <div class="w-full space-y-2">
          <label for="author_details" class="block">
            Author_details:
          </label>
          <input
            id="author_details"
            type="text"
            class="border w-full px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Enter Article Title"
            name="author_details"
            value={author_details}
            onChange={(e) => setAuthor_details(e.target.value)}
          />
        </div>
        <div class="w-full space-y-2">
          <label for="email" class="block">
            Email:
          </label>
          <input
            id="email"
            type="email"
            class="border w-full px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Enter Email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div class="w-full space-y-2">
          <label for="sup" class="block">
            Sup:
          </label>
          <input
            id="sup"
            type="text"
            class="border w-full px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Enter Article Title"
            name="sup"
            value={sup}
            onChange={(e) => setSup(e.target.value)}
          />
        </div>
        <div class="w-full space-y-2">
          <label for="tab3" class="block">
            Abstract:
          </label>
          <input
            id="tab3"
            type="text"
            class="border w-full px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Enter Article Title"
            name="tab3"
            value={tab3}
            onChange={(e) => setTab3(e.target.value)}
          />
        </div>
        <div class="w-full space-y-2">
          <label for="articleTitle" class="block">
            Article Title:
          </label>
          <input
            id="articleTitle"
            type="text"
            class="border w-full px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Enter Article Title"
            name="articleTitle"
            value={a_title}
            onChange={(e) => setA_title(e.target.value)}
          />
        </div>
        <div class="mt-4 flex items-center">
          <label for="uploadArticle" class="mr-4">
            Upload the Article:
          </label>
          <input
            id="uploadArticle"
            type="file"
            onChange={(e) => setFile(e.target.files[0])}
            class="border border-gray-300 py-2 px-4 rounded-md focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
        <button
          type="submit"
          class="mt-4 bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md"
        >
          Submit
        </button>
      </form>
    </div>
  );
};
