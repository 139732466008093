import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';


const Admin_panel = () => {
    const [manuscripts, setManuscripts] = useState([]);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        async function fetchManuscripts() {
            try {
                const manuscriptsCollection = collection(db, 'jmanuscripts');
                const querySnapshot = await getDocs(manuscriptsCollection);

                const manuscriptsData = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    manuscriptsData.push(data);
                });

                setManuscripts(manuscriptsData);
            } catch (error) {
                console.error('Error fetching manuscripts:', error);
                setError('An error occurred while fetching manuscripts.');
            }
        }

        fetchManuscripts();
    }, []);

    const back = () => {
        navigate("/admin")
    };

    return (
        <div className='px-12 py-6'>
            <span className='text-3xl font-semibold'> Admin Panel </span>
            {/* <table> */}
            <div className='mt-6 grid grid-cols-16 items-center justify-start w-full'>
                <h2 className='border-2 border-black pl-3 border font-semibold text-lg py-4'>S No.</h2>
                <h2 className='col-span-2 border-2 border-black pl-3 border font-semibold text-lg py-4'>Name</h2>
                <h2 className='col-span-3 border-2 border-black pl-3 border font-semibold text-lg py-4'>Article Title</h2>
                <h2 className='col-span-3 border-2 border-black pl-3 border font-semibold text-lg py-4'>Category</h2>
                <h2 className='col-span-3 border-2 border-black pl-3 border font-semibold text-lg py-4'>Email</h2>
                <h2 className='col-span-2 border-2 border-black pl-3 border font-semibold text-lg py-4'>Contact</h2>
                <h2 className='col-span-2 border-2 border-black pl-3 border font-semibold text-lg py-4'>Download</h2>
            </div >
            {manuscripts.map((manuscript, index) => (
                <div key={index} className='grid grid-cols-16 items-center justify-start w-full'>
                    <p className='border-2 border pt-2 min-h-full px-2 truncate hover:text-clip'>{index}</p>
                    <p className='col-span-2 border-2 border pt-2 min-h-full px-2 '>{manuscript.name}</p>
                    <p className='col-span-3 border-2 border pt-2 min-h-full px-2 '>{manuscript.j_title}</p>
                    <p className='col-span-3 border-2 border pt-2 min-h-full px-2 '>{manuscript.j_category}</p>
                    <p className='col-span-3 border-2 border pt-2 min-h-full px-2 '>{manuscript.email}</p>
                    <p className='col-span-2 border-2 border pt-2 min-h-full px-2 '>{manuscript.phone}</p>
                    <p className='col-span-2 border-2 border pt-2 min-h-full px-2 '><a
                        href={manuscript.docUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-blue-500 ml-3 hover:bg-blue-600 text-white py-1 px-3 rounded-md text-sm transition duration-300 ease-in-out"
                        download={`${manuscript.docUrl}.docx`}
                    >
                        Download
                    </a></p>
                </div>
            ))
            }
            <button
                onClick={back}
                className="bg-red-500 mt-6 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            >
                Back
            </button>
        </div >
    );
};

export default Admin_panel;
