import React, { useState, useEffect } from "react";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import { db, storage } from "../../firebase";
import { useNavigate, useParams } from "react-router-dom";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { addDoc, collection, getDoc, doc } from "firebase/firestore";
import { toast } from "react-toastify";

const initalState = {
  title: "",
  tags: [],
  category: "",
  description: "",
  astitle: "",
  asdescription: "",
  aspoints: "",
  ISSN_number: "",
};

const categoryOption = ["op1", "op2", "op3"];

const AddEditJournal = () => {
  const [form, setForm] = useState(initalState);
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(null);
  const { id } = useParams();

  const navigate = useNavigate();

  const {
    title,
    tags,
    category,
    description,
    astitle,
    asdescription,
    aspoints,
    ISSN_number,
  } = form;

  useEffect(() => {
    const uploadFile = () => {
      const storageRef = ref(storage, `images/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setProgress(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is pasued");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
            toast.info("Image upload to fire base succesfully");
            setForm((prev) => ({ ...prev, imgUrl: downloadUrl }));
          });
        }
      );
    };

    file && uploadFile();
  }, [file]);

  useEffect(() => {
    id && getJournalDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getJournalDetails = async () => {
    const docRef = doc(db, "jnal");
    const snapshot = await getDoc(docRef);
    if (snapshot.exists()) {
      setForm({ ...snapshot.data() });
    }
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleTags = (tags) => {
    setForm({ ...form, tags });
  };

  const onCategoryChange = (e) => {
    setForm({ ...form, category: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      category &&
      tags &&
      description &&
      title &&
      astitle &&
      asdescription &&
      aspoints
    ) {
      await addDoc(collection(db, "jnal"), { ...form });
      toast.success("Created Successfully");
    } else {
      return toast.error("All fields are mandatory");
    }
    navigate("/journals");
  };

  return (
    <>
      <div className="m-8">
        <div className="">
          <div className="flex-col justify-center items-center">
            <h1 className="text-center text-xl font-bold underline underline-offset-8">Upload Journal</h1>
            <div className="">
              <form className="blog-form space-y-8" onSubmit={handleSubmit}>
                <div className="w-full space-y-2">
                  <span> Title*:</span>
                  <input
                    type="text"
                    className="border w-full px-4 py-2 rounded-md"
                    placeholder="Title"
                    name="title"
                    value={title}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex-col flex justify-centre w-full">
                  <div className="grid grid-cols-2 gap-2 w-full">
                    <div className="flex flex-col rounded-md overflow-hidden space-y-2">
                      <span>Tags:</span>
                      <ReactTagInput
                        className="border-none"
                        tags={tags}
                        placeholder="Tags"
                        onChange={handleTags}
                      />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <span>Category*:</span>
                      <select
                        value={category}
                        onChange={onCategoryChange}
                        className="px-3 py-2 rounded-md border"
                      >
                        <option>Please select category</option>
                        {categoryOption.map((option, index) => (
                          <option value={option || ""} key={index}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div></div>
                </div>
                <div className="w-full space-y-2">
                  <span> Description*:</span>
                  <textarea
                    className="px-3 py-2 w-full h-[16rem] border rounded-md"
                    placeholder="Description"
                    value={description}
                    name="description"
                    onChange={handleChange}
                  />
                </div>
                <div className="space-x-4 flex">
                  <span> Upload the Banner*:</span>
                  <input
                    type="file"
                    className=""
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </div>
                <div className="w-full space-y-2">
                  <span>Aim & Scope*:</span>
                  <input
                    type="text"
                    className="border w-full px-4 py-2 rounded-md"
                    placeholder="Aim & Scope Title"
                    name="astitle"
                    value={astitle}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-full space-y-2">
                  <span>Aim & Scope Description*:</span>
                  <textarea
                    className="border w-full px-4 py-2 rounded-md"
                    placeholder="Aim & Scope Description"
                    value={asdescription}
                    name="asdescription"
                    onChange={handleChange}
                  />
                </div>
                <div className="w-full space-y-2">
                  <span>Aim & Scope Points*:</span>
                  <textarea
                    className="border w-full px-4 py-2 rounded-md"
                    placeholder="Aim & Scope Points"
                    value={aspoints}
                    name="aspoints"
                    onChange={handleChange}
                  />
                </div>
                <div className="w-full space-y-2">
                  <span>ISSN Number: <span className="italic text-gray-400">(&nbsp;Format: (ISSN 0000-0000)&nbsp;)</span></span>
                  <input
                    type="text"
                    className="border w-full px-4 py-2 rounded-md"
                    placeholder="ISSN Number"
                    name="ISSN_number"
                    value={ISSN_number}
                    onChange={handleChange}
                  />
                </div>
                <div className="">
                  <button
                    className="duration-300 ease-in-out bg-[#7968ec] px-6 md:px-10 py-2.5 md:py-4  rounded-full text-white text-sm md:text-md font-semibold mt-2 hover:bg-[#7D69F9] hover:drop-shadow-md md:hover:drop-shadow-lg hover:shadow-[#BDB5EE] md:hover:shadow-[#BDB5EE]"
                    type="submit"
                    disabled={progress !== null && progress < 100}
                  >
                    Sumbit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEditJournal;
