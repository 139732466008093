import React, { useEffect, useState } from "react";
import {
  collection,
  updateDoc,
  getDocs,
  doc,
  deleteField,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate } from "react-router";

const Manage_jnal = () => {
  const [jnals, setJnals] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const back = () => {
    navigate("/admin");
  };

  const add_art = (title_jnal) => {
    navigate("/add_articel", { state: { title: title_jnal } });
  };

  const delete_art = async (jnalIndex, articleIndex) => {
    try {
      const jnalRef = collection(db, "jnal");
      const querySnapshot = await getDocs(jnalRef);
      let jnalDocId = "";

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.title === jnals[jnalIndex].title) {
          jnalDocId = doc.id;
        }
      });

      if (jnalDocId !== "") {
        const jnalDocRef = doc(db, "jnal", jnalDocId);
        const jnalDocSnapshot = await getDoc(jnalDocRef);
        const jnalData = jnalDocSnapshot.data();
        const updatedArticles = jnalData.article.filter(
          (_, index) => index !== articleIndex
        );
        await updateDoc(jnalDocRef, { article: updatedArticles });
        const updatedSnapshot = await getDocs(jnalRef);
        const updatedJnalData = [];
        updatedSnapshot.forEach((doc) => {
          updatedJnalData.push(doc.data());
        });
        setJnals(updatedJnalData);
      }
    } catch (error) {
      console.error("Error deleting article:", error);
      setError("An error occurred while deleting the article.");
    }
  };

  useEffect(() => {
    async function fetchManuscripts() {
      try {
        const jnalCollection = collection(db, "jnal");
        const querySnapshot = await getDocs(jnalCollection);

        const jnalData = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          jnalData.push(data);
        });
        setJnals(jnalData);
      } catch (error) {
        console.error("Error fetching jnals:", error);
        setError("An error occurred while fetching jnals.");
      }
    }

    fetchManuscripts();
  }, []);
  return (
    <div className="px-12 py-6">
      <div className="text-3xl font-semibold text-center"> Manage Journal </div>
      <br />
      <br />
      {jnals.map((jnal, index) => (
        <div
          key={index}
          className="grid grid-cols-11 items-center justify-start w-full"
        >
          <p className="border-2 border pt-2 min-h-full px-2 truncate hover:text-clip">
            {index + 1}
          </p>
          <p className="col-span-5 border-2 border pt-2 min-h-full px-2 truncate hover:text-clip">
            {jnal.title}
          </p>
          <p className="col-span-5 border-2 border pt-2 min-h-full px-2 py-2">
            {jnal.article &&
              jnal.article.map((item, aindex) => (
                <div
                  key={aindex}
                  className="flex items-center border-b border-gray-200 py-3"
                >
                  <span className="text-gray-700 text-sm font-semibold">
                    {aindex + 1}
                  </span>
                  <span className="text-blue-900 ml-3 text-sm font-medium">
                    {item.atitle}
                  </span>
                  <a
                    href={item.fileLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-blue-500 ml-3 hover:bg-blue-600 text-white py-1 px-3 rounded-md text-sm transition duration-300 ease-in-out"
                    download={`${item.atitle}.pdf`}
                  >
                    Open
                  </a>
                  <button
                    className="bg-red-500 ml-3 hover:bg-red-600 text-white py-1 px-3 rounded-md text-sm transition duration-300 ease-in-out"
                    onClick={() => delete_art(index, aindex)}
                  >
                    Delete
                  </button>
                </div>
              ))}
            <button
              className=" bg-green-500 hover:green-red-700 text-white py-1 px-3 rounded"
              onClick={() => add_art(jnal.title)}
            >
              Add Article
            </button>
          </p>
        </div>
      ))}
      <button
        onClick={back}
        className="bg-red-500 mt-6 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
      >
        Back
      </button>
    </div>
  );
};

export default Manage_jnal;
