import React, { useContext } from 'react'
import { useNavigate } from 'react-router';
import { auth } from '../../firebase';
import { AuthContext } from '../../App';

const Admin = () => {
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);

    const admin_panel = () => {
        navigate("/admin_panel");
    }
    const add_jnal = () => {
        navigate("/add_jnal")
    }
    const add_eb = () => {
        navigate('/add_eb')
    }
    const manage_jnal = () => {
        navigate("/manage_jnal")
    }
    const handleSignOut = async () => {
        try {
            await auth.signOut();
            authContext.setIsAuthenticated(false);
            navigate('/admin_login');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };
    return (
        <div className='px-4 py-4'>
            <span className='text-3xl font-semibold'> Admin Dashboard </span>
            <br />
            <button
                onClick={admin_panel}
                className="bg-blue-500 mt-6 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-48">
                Admin Panel
            </button>
            <br />
            <button
                onClick={add_jnal}
                className="bg-blue-500 mt-6 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-48">
                Add Journal
            </button>
            <br />
            <button
                onClick={add_eb}
                className="bg-blue-500 mt-6 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-48">
                Add Editorial Board
            </button>
            <br />
            <button
                onClick={manage_jnal}
                className="bg-blue-500 mt-6 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-48">
                Manage Journal
            </button>
            <br />
            <button
                onClick={handleSignOut}
                className="bg-red-500 mt-6 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                Sign Out
            </button>
        </div>
    )
}

export default Admin;